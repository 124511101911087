import { makeAutoObservable, action } from 'mobx';
import { makePersistable, isHydrated, isPersisting } from 'mobx-persist-store';
import commonServices from '@/services/commonServices';
import { ThemeModeType, CountryType, CategoryType, PlatformType } from './types';
import StringUtils from '@/utils/StringUtils';
import i18n from '@/i18n/config';

export default class CommonStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true }); // this绑定当前Store
    makePersistable(this, {
      name: 'CommonStore',
      properties: ['theme', 'mobileEnterDate'],
      storage: window.localStorage,
    }).then(
      action((persistStore) => {
        // console.log('persistStore', persistStore.isHydrated);
      })
    );
  }

  get isHydrated() {
    return isHydrated(this);
  }

  get isPersisting() {
    return isPersisting(this);
  }

  mobileEnterDate: string = ''; // 用户点击关闭DeviceReminder页的日期
  setMobileEnterDate(value: string) {
    this.mobileEnterDate = value;
  }

  theme: ThemeModeType = 'dark';
  setTheme(mode: ThemeModeType) {
    this.theme = mode;
  }

  async getOssPolicy() {
    const res = await commonServices.getOssPolicy();
    return Promise.resolve(res);
  }

  async getAwsConfig() {
    const res = await commonServices.getAwsConfig();
    return Promise.resolve(res);
  }

  countryList: CountryType[] = [];
  async getCountries() {
    const res = await commonServices.getCountries();
    this.countryList = res.list;
    return Promise.resolve(res.list);
  }

  getCountryName(code?: string) {
    const country = this.countryList.find((c) => c.code === code);
    const lang = i18n.language === 'zh' ? 'zh' : i18n.language === 'ko' ? 'ko' : 'en';
    return country ? country[lang] : '-';
  }

  phoneCodeList = [
    { zh: '美国', name: 'country_USA', code: 1 },
    { zh: '加拿大', name: 'country_CAN', code: 1 },
    { zh: '韩国', name: 'country_KRW', code: 82 },
    { zh: '中国', name: 'country_CHN', code: 86 },
  ];

  getCountryCode(name: string) {
    const country = this.phoneCodeList.find((item) => item.name === name);
    return country ? country.code : '';
  }

  categoryList: CategoryType[] = [];
  async getCategories() {
    const res = await commonServices.getCategories();
    this.setCategoryList(res.list);
    return Promise.resolve(res.list);
  }

  setCategoryList(list: CategoryType[]) {
    this.categoryList = list;
  }

  getDisplayCateList(language: 'zh' | 'en' | 'ko' = 'ko') {
    const array = this.categoryList.reduce((arr: CategoryType[], item) => {
      if (item.parentId === 0) {
        arr.push({
          ...item,
          label: item[`${language}`],
          value: item.id,
        });
      } else if (item.id > 99999 && item.id < 999999) {
        arr.forEach((a) => {
          if (item.parentId === a?.id) {
            let newItem = {
              ...item,
              label: item[`${language}`],
              value: item.id,
            };
            if (a?.children) {
              a.children.push(newItem);
            } else {
              a.children = [newItem];
            }
          }
        });
      } else {
        arr.forEach((a) => {
          a.children?.forEach((b) => {
            if (item.parentId === b?.id) {
              let newItem = {
                ...item,
                label: item[`${language}`],
                value: item.id,
              };
              if (b?.children) {
                b.children.push(newItem);
              } else {
                b.children = [newItem];
              }
            }
          });
        });
      }
      return arr;
    }, []);
    return array;
  }

  displayCateName(id?: number, language: 'zh' | 'en' | 'ko' = 'ko') {
    if (!id) {
      return '-';
    }
    let cate = '';
    let cateParentId = id;
    do {
      // eslint-disable-next-line no-loop-func
      const cateItem = this.categoryList?.find((item) => item.id === cateParentId);
      if (cateItem) {
        cate = cateItem[`${language}`] + `${cate ? `/${cate}` : cate}`;
        cateParentId = cateItem.parentId;
      } else {
        cateParentId = 0;
      }
    } while (cateParentId !== 0);
    return cate || '-';
  }

  defaultPlatformList: PlatformType[] = [
    {
      id: 1,
      name: 'platform_hao_kan',
      icon: require('@/assets/platformIcon/haokan.png'),
      sms: require('@/assets/platformCode/baidu.png'),
    },
    {
      id: 2,
      name: 'platform_tencent',
      icon: require('@/assets/platformIcon/tencent.png'),
    },
    {
      id: 3,
      name: 'platform_iqiyi',
      icon: require('@/assets/platformIcon/iqiyi.png'),
      sms: require('@/assets/platformCode/iqiyi.png'),
    },
    {
      id: 4,
      minute: 5,
      name: 'platform_bilibili',
      icon: require('@/assets/platformIcon/bili.png'),
      sms: require('@/assets/platformCode/bili.png'),
    },
    {
      id: 5,
      minute: 5,
      name: 'platform_xi_gua',
      icon: require('@/assets/platformIcon/xigua.png'),
      sms: require('@/assets/platformCode/xigua.png'),
    },
    {
      id: 6,
      name: 'platform_wei_bo',
      icon: require('@/assets/platformIcon/weibo.jpg'),
      sms: require('@/assets/platformCode/weibo.png'),
    },
    {
      id: 7,
      name: 'platform_youtube',
      icon: require('@/assets/platformIcon/youtube.png'),
    },
    {
      id: 8,
      minute: 5,
      name: 'platform_dou_yin',
      icon: require('@/assets/platformIcon/douyin.png'),
      sms: require('@/assets/platformCode/douyin.png'),
    },
    {
      id: 9,
      minute: 3,
      name: 'platform_red_book',
      icon: require('@/assets/platformIcon/red_book.jpg'),
      sms: require('@/assets/platformCode/redbook.png'),
    },
    {
      id: 10,
      minute: 15,
      name: 'platform_kuai_shou',
      icon: require('@/assets/platformIcon/kwai.jpg'),
      sms: require('@/assets/platformCode/kwai.png'),
    },
    {
      id: 11,
      name: 'platform_cloud_music',
      icon: require('@/assets/platformIcon/net_music.png'),
    },
    {
      id: 12,
      name: 'platform_wechat_video',
      icon: require('@/assets/platformIcon/shipin.jpg'),
    },
    {
      id: 13,
      name: 'platform_mei_pai',
      icon: require('@/assets/platformIcon/meipai.jpg'),
    },
    {
      id: 14,
      name: 'platform_da_yu',
      icon: require('@/assets/platformIcon/dayu.jpg'),
      sms: require('@/assets/platformCode/dayu.png'),
    },
    {
      id: 15,
      minute: 15,
      name: 'platform_dian_tao',
      icon: require('@/assets/platformIcon/diantao.png'),
      sms: require('@/assets/platformCode/diantao.png'),
    },
    {
      id: 16,
      name: 'platform_qq_world',
      icon: require('@/assets/platformIcon/qqworld.jpg'),
      sms: require('@/assets/platformCode/qqworld.png'),
    },
    { id: 99, name: 'platform_youtube', icon: require('@/assets/platformIcon/youtube.png') },
    { id: 100, name: 'platform_tiktok', icon: require('@/assets/platformIcon/tiktok.png') },
    { id: 101, name: 'platform_instagram', icon: require('@/assets/platformIcon/instagram.png') },
    { id: 102, name: 'platform_twitter', icon: require('@/assets/platformIcon/twitter.png') },
  ];

  platformList: PlatformType[] = [];
  foreignPlatformsList: PlatformType[] = [];
  async getPlatforms(isTrialVersion?: boolean) {
    const res = await commonServices.getPlatforms();
    this.savePlatforms(res.list, isTrialVersion);
    return Promise.resolve(res.list);
  }

  savePlatforms(list: PlatformType[], isTrialVersion?: boolean) {
    const excludedIds = [8, 14, 15]; // 排除的平台: 抖音 大鱼号 点淘
    const platforms = list
      .filter((item) =>
        isTrialVersion
          ? !excludedIds.includes(item.id) && item.id !== 6 // 体验版 排除平台: 微博
          : !excludedIds.includes(item.id)
      ) // 暂时不支持的平台
      .map((plat) => {
        const platform = this.defaultPlatformList.find((item) => item.id === plat.id);
        return {
          ...plat,
          ...platform,
          id: plat.id,
          name: platform?.name || plat.name,
          icon: platform?.icon || require('@/assets/platformIcon/empty.png'),
          isForeign: plat.isForeign || false,
        };
      });

    this.platformList = (platforms || []).filter((p) => p.isForeign === false);
    this.foreignPlatformsList = (platforms || []).filter((p) => p.isForeign);
  }

  getPlatformName(id?: number | string) {
    const platform = this.defaultPlatformList.find((p) => p.id === Number(id));
    return platform?.name || id;
  }

  getPlatformIcon(id?: number | string) {
    const platform = this.defaultPlatformList.find((p) => p.id === Number(id));
    return platform?.icon || require('@/assets/platformIcon/empty.png');
  }

  getPlatformMinute(id?: number | string) {
    const platform = this.defaultPlatformList.find((p) => p.id === Number(id));
    return platform?.minute || 10;
  }

  getPlatformSms(id?: number | string) {
    const platform = this.defaultPlatformList.find((p) => p.id === Number(id));
    return platform?.sms || require('@/assets/platformIcon/empty.png');
  }

  // 获取youtube 视频详情
  async getYoutubeVideo(youtubeVideoId: string) {
    const params = {
      part: 'snippet,contentDetails',
      key: 'AIzaSyAq-VaO7x6tgXwSUArx6oeNMoa_TXMdzPI',
      id: youtubeVideoId,
    };
    const res = await commonServices.getYoutubeVideo(params);
    return Promise.resolve(res);
  }

  // 获取tiktok 视频详情
  async getTiktokVideo(videoUrl: string) {
    const res = await commonServices.getTiktokVideo(videoUrl);
    return Promise.resolve(res);
  }

  // 获取错误信息模板
  async getErrorTemplates(lang = 'en', kid = 'UNKNOWN') {
    const res = await commonServices.getErrorTemplates({ lang, kid });
    return Promise.resolve(res);
  }

  //获取指定错误信息模板
  async getAppointErrorTemplates(lang = 'en', tidList: number[] = []) {
    const res = await commonServices.getAppointErrorTemplates({ lang, tidList });
    return Promise.resolve(res);
  }

  // 友盟初始化: 集成应用的appKey
  uMengInit() {
    const { aplus_queue } = window as any;

    // 测试环境Key: 64649905ba6a5259c4536799
    // 生产环境Key: 64867872e31d6071ec4ebbb4
    const key =
      StringUtils.getEnvironment() === 'dev'
        ? '64649905ba6a5259c4536799'
        : '64867872e31d6071ec4ebbb4';

    aplus_queue.push({
      action: 'aplus.setMetaInfo',
      arguments: ['appKey', key],
    });
  }

  // 记录页面点击事件
  uMengRecord(eventCode: string, eventParams: any) {
    const { aplus_queue } = window as any;
    aplus_queue.push({
      action: 'aplus.record',
      arguments: [eventCode, 'CLK', eventParams],
    });
  }
}
